import { Component, OnInit } from '@angular/core';
import { BaseService } from '../shared/services/base-service/base.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  constructor(private baseService: BaseService ) { }

  ngOnInit() { }

}
