import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'highlightTitle' })
export class HighLightTitlePipe implements PipeTransform {
  transform(text: string): string {
    if (text === null) {
      return null;
    }
    let output: string = null;
    output = `<span class="text-red">${text.charAt(0)}</span>`;
    text = text.substring(1, text.length);
    while (text.indexOf(' ') > -1) {
      output = output + text.substring(0, text.indexOf(' ') + 1);
      output = output + `<span class="text-red">${text.charAt(text.indexOf(' ') + 1)}</span>`;
      text = text.substring((text.indexOf(' ') + 2), text.length);
    }
    output = output + text;
    return output;
  }
}

// Usage:
// <input type="text" [(ngModel)]="filter">
// <div [innerHTML]="myAwesomeText  | highlight : filter"></div>
