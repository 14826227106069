import { Component, OnInit } from '@angular/core';
import { BaseService } from '../shared/services/base-service/base.service';
import { i18n, I18nService } from '../shared/services/i18n-service/i18n.service';
import { SessionService } from '../shared/services/session-service/session.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DISPLAY_ANIMATION } from '../shared/animation/display.animation';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [ DISPLAY_ANIMATION ]
})
export class HeaderComponent implements OnInit {

  @i18n('HEADER_TITLE')
  public headerTitle: string;
  @i18n('HEADER_SUB_TITLE')
  public headerSubTitle: string;

  public page: string;

  public isOpened: boolean;
  public isLanguageSectionOpened: boolean;

  constructor(
    private baseService: BaseService,
    private i18nService: I18nService,
    private sessionService: SessionService,
    private router: Router
  ) {
    this.i18nService.bindI18nStrings(this);
    this.isOpened = false;
    this.isLanguageSectionOpened = false;
  }

  ngOnInit() {
    switch (window.location.pathname) {
      case '/what-we-offer':
        this.page = 'WHAT_WE_OFFER';
        break;
      case '/who-we-are':
        this.page = 'WHO_WE_ARE';
        break;
      case '/gallery':
        this.page = 'GALLERY';
        break;
      case '/contact-us':
        this.page = 'CONTACT_US';
        break;
      default:
        this.page = 'HOME';
        break;
    }
  }

  public openHamburgerMenu(): void {
    this.isOpened = !this.isOpened;
  }

  public getImg(imgName: string): string {
      return this.baseService.getImagePath() + imgName;
  }

  public selectPage(page: string) {
    this.page = page;
    this.openHamburgerMenu();
  }

  public redirectToPage(page: string) {
    this.router.navigateByUrl(page);
  }

  public selectLanguage(language: string) {
    const USER_LANGUAGE = 'UserLanguage';
    switch (language) {
      case 'USA':
        this.sessionService.setItem(USER_LANGUAGE, 'us');
        break;
      case 'ITALY':
        this.sessionService.setItem(USER_LANGUAGE, 'it');
        break;
      case 'ENGLAND':
        this.sessionService.setItem(USER_LANGUAGE, 'en');
        break;
      case 'GERMANY':
        this.sessionService.setItem(USER_LANGUAGE, 'de');
        break;
      case 'FRANCE':
        this.sessionService.setItem(USER_LANGUAGE, 'fr');
        break;
      case 'SPAIN':
        this.sessionService.setItem(USER_LANGUAGE, 'es');
        break;
      case 'PORTUGAL':
        this.sessionService.setItem(USER_LANGUAGE, 'pt');
        break;
    }
    location.reload();
  }

}
