import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseService } from './services/base-service/base.service';
import { LoggerService } from './services/logger-service/logger.service';
import { ConsoleLoggerService } from './services/console-logger-service/console-logger.service';
import { SessionService } from './services/session-service/session.service';
import { SafeHtmlPipe } from './services/safe-html.pipe';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { HighLightTitlePipe } from './pipes/highlightTitlePipe';
import { I18nService, provideI18n } from './services/i18n-service/i18n.service';
import { MaterialModule } from './material/material.module';
import { InfoModalComponent } from './modals/info-modal/info-modal.component';
import { CutPipe } from './pipes/cutPipe';
import { ValidatorService } from './services/validator-service/validator.service';
import { LoaderComponent } from './component/loader/loader.component';
import { LoaderService } from './component/loader/loader.service';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    SafeHtmlPipe,
    HighLightTitlePipe,
    CutPipe,
    InfoModalComponent,
    LoaderComponent
  ],
  declarations: [
    SafeHtmlPipe,
    HighLightTitlePipe,
    InfoModalComponent,
    CutPipe,
    LoaderComponent
  ],
  providers: [
    I18nService,
    { provide: APP_INITIALIZER, useFactory: provideI18n, deps: [ I18nService ], multi: true },
    BaseService,
    { provide: LoggerService, useClass: ConsoleLoggerService },
    SessionService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    HighLightTitlePipe,
    CutPipe,
    ValidatorService,
    LoaderService
  ],
  entryComponents: [ InfoModalComponent ]
})
export class AppSharedModule { }
