import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// tslint:disable-next-line:use-pipe-transform-interface
@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements Pipe {

  name: string;
  pure?: boolean;

  constructor(private sanitizer: DomSanitizer) { }

  transform(html) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(html);
  }

}
