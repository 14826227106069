import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-we-offer',
  templateUrl: './what-we-offer.component.html',
  styleUrls: ['./what-we-offer.component.scss']
})
export class WhatWeOfferComponent implements OnInit {

  public storiaBoxAnimation: string;
  public storiaAnimation: string;
  public cronologiaAnimation: string;
  public hideLogo: boolean;

  constructor() {
    this.storiaBoxAnimation = 'active';
    this.storiaAnimation = 'inactive';
    this.cronologiaAnimation = 'inactive';
    this.hideLogo = false;
  }

  ngOnInit() { }

  expand(boxName: string) {
    this.storiaBoxAnimation = 'inactive';
    setTimeout(() => {
      switch (boxName) {
        case 'STORIA':
          this.storiaAnimation = 'active';
          break;
        case 'CRONOLOGIA':
          this.cronologiaAnimation = 'active';
          break;
      }
      this.hideLogo = true;
    }, 300);
  }

  reduce() {
    this.storiaAnimation = 'inactive';
    this.cronologiaAnimation = 'inactive';
    setTimeout(() => {
      this.hideLogo = false;
      this.storiaBoxAnimation = 'active';
    }, 300);
  }

}
