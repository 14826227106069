import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxImageGalleryComponent, GALLERY_IMAGE, GALLERY_CONF } from 'ngx-image-gallery';
import { LoggerService } from '../../shared/services/logger-service/logger.service';
import { I18nService } from 'src/app/shared/services/i18n-service/i18n.service';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {

  // get reference to gallery component
  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent;

  // gallery configuration
  public conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: true,
    inline: true,
    backdropColor: '#FFFFFF',
    showCloseControl: false,
    showExtUrlControl: false,
    showThumbnails: true,
    imagePointer: true
  };

  // gallery images
  public images: GALLERY_IMAGE[];

  constructor(
    private logger: LoggerService,
    private i18nService: I18nService
  ) {
    this.images = [
      {
        url: 'assets/img/Gallery/grafologia1.jpg',
        title: this.i18nService.getValueForKey('GALLERY.GRAFOLOGIA'),
        thumbnailUrl: 'assets/img/Gallery/grafologia1.jpg'
      },
      {
        url: 'assets/img/Gallery/grafologia2.jpg',
        title: this.i18nService.getValueForKey('GALLERY.GRAFOLOGIA'),
        thumbnailUrl: 'assets/img/Gallery/grafologia2.jpg'
      },
      {
        url: 'assets/img/Gallery/dna1.jpg',
        title: this.i18nService.getValueForKey('GALLERY.DNA'),
        thumbnailUrl: 'assets/img/Gallery/dna1.jpg'
      },
      {
        url: 'assets/img/Gallery/dna2.jpg',
        title: this.i18nService.getValueForKey('GALLERY.DNA'),
        thumbnailUrl: 'assets/img/Gallery/dna2.jpg'
      },
      {
        url: 'assets/img/Gallery/crimine1.png',
        title: this.i18nService.getValueForKey('GALLERY.CRIME'),
        thumbnailUrl: 'assets/img/Gallery/crimine1.png'
      },
      {
        url: 'assets/img/Gallery/laboratorio1.jpg',
        title: this.i18nService.getValueForKey('GALLERY.LABORATORIO'),
        thumbnailUrl: 'assets/img/Gallery/laboratorio1.jpg'
      },
      {
        url: 'assets/img/Gallery/laboratorio2.jpg',
        title: this.i18nService.getValueForKey('GALLERY.LABORATORIO'),
        thumbnailUrl: 'assets/img/Gallery/laboratorio2.jpg'
      },
      {
        url: 'assets/img/Gallery/laboratorio3.jpg',
        title: this.i18nService.getValueForKey('GALLERY.LABORATORIO'),
        thumbnailUrl: 'assets/img/Gallery/laboratorio3.jpg'
      },
      {
        url: 'assets/img/Gallery/laboratorio4.jpg',
        title: this.i18nService.getValueForKey('GALLERY.LABORATORIO'),
        thumbnailUrl: 'assets/img/Gallery/laboratorio4.jpg'
      },
      {
        url: 'assets/img/Gallery/impronta1.jpg',
        title: this.i18nService.getValueForKey('GALLERY.IMPRONTA'),
        thumbnailUrl: 'assets/img/Gallery/impronta1.jpg'
      },
      {
        url: 'assets/img/Gallery/impronta2.png',
        title: this.i18nService.getValueForKey('GALLERY.IMPRONTA'),
        thumbnailUrl: 'assets/img/Gallery/impronta2.png'
      },
      {
        url: 'assets/img/Gallery/impronta3.png',
        title: this.i18nService.getValueForKey('GALLERY.IMPRONTA'),
        thumbnailUrl: 'assets/img/Gallery/impronta3.png'
      },
      {
        url: 'assets/img/Gallery/impronta4.jpg',
        title: this.i18nService.getValueForKey('GALLERY.IMPRONTA'),
        thumbnailUrl: 'assets/img/Gallery/impronta4.jpg'
      }
    ];
  }

  ngOnInit() {
    setTimeout(() => {
      this.openGallery();
    }, 2000);
  }

  // METHODS
  // open gallery
  openGallery(index: number = 0) {
    this.ngxImageGallery.open(index);
  }

  // close gallery
  closeGallery() {
    this.ngxImageGallery.close();
  }

  // set new active(visible) image in gallery
  newImage(index: number = 0) {
    this.ngxImageGallery.setActiveImage(index);
  }

  // next image in gallery
  nextImage(index: number = 0) {
    // this.ngxImageGallery.next(index);
    this.ngxImageGallery.next();
  }

  // prev image in gallery
  prevImage(index: number = 0) {
    // this.ngxImageGallery.prev(index);
    this.ngxImageGallery.prev();
  }

  /**************************************************/

  // EVENTS
  // callback on gallery opened
  galleryOpened(index) {
    this.logger.info('Gallery opened at index ', index);
  }

  // callback on gallery closed
  galleryClosed() {
    this.logger.info('Gallery closed.');
  }

  // callback on gallery image clicked
  galleryImageClicked(index) {
    this.logger.info('Gallery image clicked with index ', index);
  }

  // callback on gallery image changed
  galleryImageChanged(index) {
    this.logger.info('Gallery image changed to index ', index);
  }

  // callback on user clicked delete button
  deleteImage(index) {
    this.logger.info('Delete image at index ', index);
  }

}
