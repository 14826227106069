import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  public title: string;
  public content: string;
  public buttonLabel: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<InfoModalComponent>) {
    this.title = data.title;
    this.content = data.content;
    this.buttonLabel = data.buttonLabel;
  }

  ngOnInit() { }

  closeDialog() {
    this.dialogRef.close();
  }

}
