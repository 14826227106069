import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

export const DISPLAY_ANIMATION =
  trigger('displayAnimationTrigger', [
      state('inactive', style({
          display: 'none',
          transform: 'scale(0.5)'
      })),
      state('active',   style({
          display: 'block',
          transform: 'scale(1)'
      })),
      transition('inactive => active', animate('300ms ease-in')),
      transition('active => inactive', animate('300ms ease-out'))
  ]);
