import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable, of } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';

export function provideI18n(i18nService: I18nService) {
  return () => i18nService.loadConfiguration();
}

export function i18n(i18nKey: string) {
  return  (target, key: string): any => {
      target.i18nBindings = target.i18nBindings || [];
      target.i18nBindings.push({
          propertyName: key,
          propertyI18nKey: i18nKey
      });
    };
}

export function getCurrentLanguageI18n(i18nService: I18nService) {
  const language = i18nService.getLanguage();
  return language === 'us' ? 'en-US' : language;
}

interface I18nBind {
    propertyName: string;
    propertyI18nKey: string;
}

/**
 * Il servizio funziona nel seguente modo:
 * 1. Al bootstrap dell'applicazione carica un file di configurazione './assets/i18n.config.json'
 *    Su questo file sono contenuti i dettagli per la gestione i18n dell'app
 * 2. Carica il file con le traduzioni di fallback (se una traduzione per una specifica lingua non fosse disponibile)
 * 3. Carica il file con le traduzioni per la lingua corrente
 * 4. La lingua corrente viene letta dal session service oppure dalla lingua impostata nel browser
 **/

@Injectable()
export class I18nService {

  private readonly configFile = './assets/i18n/i18n.config.json';

  private defaultLanguageProperties: Object = {};
  private currentLanguageProperties: Object = {};
  private config: Object = {};

  constructor(private http: Http) { }

  /**
    *
    * Legge il file di configurazione i18n.config.json
    **/
  loadConfiguration() {

    return new Promise((resolve, reject) => {

      this.http.get(this.configFile)
        .pipe(
          map(res => res.json()),
          flatMap(json => {
            this.config = json;
            return this.http.get(this.getFallbackFilePath(json));
          })
        )
        .subscribe(
          defaultLanguageResponse => {
            this.defaultLanguageProperties = defaultLanguageResponse.json();
            this.currentLanguageProperties = this.defaultLanguageProperties;
            // Adesso carico le traduzioni per la lingua corrente
            // Se la chiamata va in errore non fa niente perche' abbiamo
            // le traduzioni di default, quindi faccio resolve
            const currentLangPath = this.getCurrentLanguagePath(this.config);
            this.http.get(currentLangPath)
            .subscribe(
              currentLanguageResponse => {
                this.currentLanguageProperties = currentLanguageResponse.json();
                // this.printThis();
                resolve(true);
              },
              error => resolve(true)
            );
          },
          error => reject(error)
          );
        });
  }

  public getLanguage(): string {
    const language = this.getUserLanguage();
    return language ? language : navigator.language.substr(0, 2);
  }

  private getUserLanguage(): any {
    let language: any;
    const USER_LANGUAGE = 'UserLanguage';
    const userLanguageFromStorage = sessionStorage.getItem(USER_LANGUAGE);
    if (userLanguageFromStorage !== undefined && sessionStorage.getItem(USER_LANGUAGE) !== 'undefined') {
      language =  JSON.parse(userLanguageFromStorage);
    }
    return language;
  }

  private getPathPrefix(config: any): string {
    return `${config.propertiesPath}/${config.bundlePrefix}`;
  }

  private getFallbackFilePath(config: any): string {
    return `${this.getPathPrefix(config)}-${config.defaultLang}.json`;
  }

  private getCurrentLanguagePath(config: any): string {
    return `${this.getPathPrefix(config)}-${this.getCurrentLanguage()}.json`;
  }

  private getCurrentLanguage(): string {
    const language = this.getUserLanguage();
    return language ? language : navigator.language.substr(0, 2);
  }

  private isDefaultLanguage(): boolean {
    return this.getFallbackFilePath(this.config) === this.getCurrentLanguagePath(this.config);
  }

  public getValueForKey(key: string): string {
    /**
     * @returns La traduzione per la lingua corrente se disponibile.
     * Altrimenti la traduzione di default se disponibile.
     * Altrimenti se non è disponibile nessuna traduzione restituisce la chiave
    */
    const translation = this.currentLanguageProperties[key];
    const defaultTranslation = this.defaultLanguageProperties[key];

    return translation ? translation : (defaultTranslation ? defaultTranslation : '***' + key + '***');
  }

  /**
   * Traduce le stringhe annotate con @i18n()
   */
  public bindI18n(target: any) {
    const bindings = target.i18nBindings;

    of(bindings)
      .pipe(
        map(bind => <I18nBind>bind)
      )
      .subscribe(propertyBind => {
        target[propertyBind.propertyName] = this.getValueForKey(propertyBind.propertyI18nKey);
      });

  }

  /**
   * Traduce le stringhe annotate con @i18n()
   */
  public bindI18nStrings(target: any) {
    const bindings = target.i18nBindings;

    of(bindings)
      .pipe(
        map(bind => <I18nBind[]>bind)
      )
      .subscribe(propertyBind => {
        for (const prop of propertyBind) {
          target[prop.propertyName] = this.getValueForKey(prop.propertyI18nKey);
        }
      });

  }

}
