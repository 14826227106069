import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable()
export class ValidatorService {

  public dirtyOrTouched(formControl: FormControl): boolean {
    return (formControl.dirty || formControl.touched) ? true : false;
  }

  public dirty(formControl: FormControl): boolean {
    return formControl.dirty ? true : false;
  }

  public touched(formControl: FormControl): boolean {
    return formControl.touched ? true : false;
  }

}
