import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppSharedModule } from './shared/shared.module';
import { I18nPipe } from './shared/services/i18n-service/i18n.pipe';
import { I18nService, provideI18n } from './shared/services/i18n-service/i18n.service';
import { HeaderComponent } from './header/header.component';
import { HomePageComponent } from './home-page/home-page.component';
import { FooterComponent } from './footer/footer.component';
import { MaterialModule } from './shared/material/material.module';
import { HighLightTitlePipe } from './shared/pipes/highlightTitlePipe';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { WhatWeOfferComponent } from './what-we-offer/what-we-offer.component';
import { GalleryComponent } from './gallery/gallery.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { ImageGalleryComponent } from './gallery/image-gallery/image-gallery.component';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { CutPipe } from './shared/pipes/cutPipe';
import { PageLogoComponent } from './shared/component/page-logo/page-logo.component';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    I18nPipe,
    HomePageComponent,
    HeaderComponent,
    FooterComponent,
    ContactUsComponent,
    WhatWeOfferComponent,
    GalleryComponent,
    WhoWeAreComponent,
    ImageGalleryComponent,
    PageLogoComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppSharedModule,
    MaterialModule,
    NgxImageGalleryModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      tapToDismiss: true,
      closeButton: true
    }),
    ToastContainerModule
  ],
  providers: [
    I18nService,
    { provide: APP_INITIALIZER, useFactory: provideI18n, deps: [ I18nService ], multi: true },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false} },
    HighLightTitlePipe,
    CutPipe
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
