import { Injectable } from '@angular/core';

@Injectable()
export class SessionService {

  constructor() { }

  public setItem(key: string, item: any): void {
    sessionStorage.setItem(key, JSON.stringify(item));
  }

  public getItem(key: string): any {
    return JSON.parse(sessionStorage.getItem(key));
  }

  public removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

}
