import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  private min = 1;
  private max = 6;
  public img: number;

  constructor() {
    this.img = Math.floor(Math.random() * (this.max - this.min + 1)) + this.min;
  }

  ngOnInit() { }

}
