import { Component, OnInit, ViewContainerRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidatorService } from '../shared/services/validator-service/validator.service';
// import { ToastrService } from '../shared/services/toastr-service/toastr.service';
import { LoaderService } from '../shared/component/loader/loader.service';
import { emailPattern, alphaNumericPatternWithSpecialChar } from '../shared/constants/patterns.constant';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { i18n } from '../shared/services/i18n-service/i18n.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;

  @i18n('CONTACT_US.MAIL_VALUE')
  public mailAddress: string;

  public formGroup: FormGroup;

  public mailSent: boolean;

  public completeMailAddress: String;

  constructor(
    public _formBuilder: FormBuilder,
    public validatorService: ValidatorService,
    public loaderService: LoaderService,
    public toastr: ToastrService
  ) {
    this.mailSent = false;
  }

  ngOnInit() {
    this.toastr.overlayContainer = this.toastContainer;
    this.formGroup = this._formBuilder.group({
      name: [null, [Validators.required, Validators.maxLength(50), Validators.pattern(alphaNumericPatternWithSpecialChar)]],
      mail: [null, [Validators.required, Validators.maxLength(100), Validators.pattern(emailPattern)]],
      text: [null, [Validators.maxLength(500), Validators.pattern(alphaNumericPatternWithSpecialChar)]]
    });
    this.completeMailAddress = 'mailto:' + this.mailAddress;
  }

  send() {
    this.loaderService.show();
    setTimeout(() => {
      this.loaderService.hide();
      this.toastr.success('messaggio', 'titolo');
      this.mailSent = true;
    }, 1000);
  }

}
