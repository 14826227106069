import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';

@Injectable()
export class BaseService {

  public static defaultLabel = { label: 'Available Options', value: 0 };

  private imagePath: string;

  // Dynamic address
  SERVER_ADDRESS: string;
  SERVER_PORT: string;
  SERVER_PORT_TEST: string;

  constructor() {
    // this.imagePath = this.config.getImagePath();
  }

  public getImagePath() {
    return this.imagePath;
  }

  public getImageURI(img: string) {
    return this.imagePath + img;
  }

  public handleError(error: Response | any) {
    return Observable.throw((error && error.json()) || 'Server error');
  }

  public extractJson(res: Response | any) {
    return res.json() ? res.json() : {};
  }

  public extractResponse(res: Response) {
    const body = res;
    return body || {};
  }

}
