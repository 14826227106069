import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'cut' })
export class CutPipe implements PipeTransform {

  transform(text: string, length: number, apply?: boolean): string {
    if (text === null) {
      return null;
    }
    if ((text.length < length + 1) || (apply !== null && !apply)) {
      return text;
    }
    return text.substring(0, length) + '...';
  }

}
